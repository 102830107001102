import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LightTooltip from "../LightTooltip";

export default function SwitchLivestock({ activeCrop, changeCrop }) {
  const switchh = ["Cattle", "Buffalo", "Goat", "Sheep", "Pig", "Chicken"];
  const switchid = ["cattle", "buffalo", "goat", "sheep", "pig", "poultry"];
  const disvar = {
    cattle: false,
    buffalo: false,
    goat: false,
    sheep: false,
    pig: false,
    poultry: false,
  };

  const padd = 8;
  const season = [
    "The analysis is done for milk production.",
    "The analysis is done for milk production.",
    "The analysis is done for meat production.",
    "The analysis is done for meat production.",
    "The analysis is done for meat production.",
    "The analysis is done for meat production.",
  ];

  const AntSwitch = styled(Switch)(({ theme }) => ({
    "width": 32 + padd,
    "height": 14 + padd,
    "padding": padd / 2,
    "display": "flex",

    "& .MuiSwitch-switchBase": {
      "padding": 2 + padd / 2,
      "&.Mui-checked": {
        "transform": "translateX(16px)",
        "color": "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#61c258" : "#4ba046",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 10,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 14 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.25)" : "rgba(0,0,0,.10)",
      boxSizing: "border-box",
    },
    "&:hover": {
      "backgroundColor": theme.palette.mode === "dark" ? "#554d38" : "#ffe89c",
      "opacity": 1,
      "borderRadius": 12,
      "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.25)" : "rgba(255,255,255,.7)",
      },
    },
  }));

  const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    "alignItems": "flex-start",
    "&.Mui-disabled .MuiTypography-body2": {
      color: theme.palette.mode === "dark" ? "#888" : "#ccc",
    },
  }));

  return (
    <FormControl component="fieldset" variant="standard" sx={{ paddingBottom: 1, paddingLeft: 6 }}>
      <FormGroup>
        {switchh.map((sname, index) => (
          <CustomFormControlLabel
            control={<AntSwitch inputProps={{ "aria-label": "ant design" }} checked={activeCrop[switchid[index]]} onChange={changeCrop(switchid[index])} name={switchid[index]} />}
            key={switchid[index]}
            disabled={disvar[switchid[index]]}
            label={
              <Typography variant="body2" sx={{ paddingLeft: 1 }}>
                {sname}
                {disvar[switchid[index]] === false && (
                  <LightTooltip
                    title={
                      <>
                        <span>{season[index]}</span>
                        <br />
                        {/*<Link
                          href={`#/resources?tab=2&term=${switchh[index].toLowerCase()}`}
                          target="_blank"
                          sx={(theme) => ({
                            color: theme.palette.mode === "dark" ? "black" : "white",
                            fontWeight: "bold",
                          })}
                        >
                          Read More
                        </Link>*/}
                      </>
                    }
                    placement="right"
                    arrow
                  >
                    <IconButton sx={{ padding: 0, margin: 0, paddingX: "4px" }}>
                      <InfoOutlinedIcon sx={{ fontSize: "12px", padding: 0, margin: 0 }} />
                    </IconButton>
                  </LightTooltip>
                )}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
